import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs/Observable";
import { JWTTokenService } from "./jwt.decoder";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { NotificationService } from "src/app/notification.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public toast: NotificationService, public authService: AuthService,
    public router: Router, public jwtService: JWTTokenService) {

    // setInterval(() => {
    //   const token = this.authService.getToken();
    //   if (token && this.jwtService.isTokenExpired()) {
    //     this.authService.logout();
    //   }
    // }, 10000);
  }


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.headers.get("token") !== "noToken" && !request.url.includes('password')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`
        }
      });
    }

    if (!request.headers.has("Content-Type") && !request.headers.has("upload")) {
      request = request.clone({
        headers: request.headers.set("Content-Type", "application/json")
      });
    }


    request = request.clone({
      headers: request.headers.set("Accept", "application/json")
    });

    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {

          if (err.status !== 401) {
            return;
          }

          this.router.navigate(['login']);
        }

        if (err instanceof HttpErrorResponse && err.status === 504) {
          this.toast.showError("le serveur a répondu avec un statut de 504 (Gateway Timeout)", "erreur serveur ");
        }
      }));
  }

  isTokenExpired(token: string): boolean {
    const expiration = (JSON.parse(atob(token.split('.')[1]))).exp;

    return Math.floor(Date.now() / 1000) >= expiration;
  }
}
