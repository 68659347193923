import { NotificationService } from 'src/app/notification.service';
import { EmailService } from './../../../simlait/service/emai.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/pages/core/auth.service';
import { User } from 'src/app/pages/core/user';
import { emailValidator } from '../../utils/app-validators';

@Component({
  selector: 'app-top-info-content',
  templateUrl: './top-info-content.component.html',
  styleUrls: ['./top-info-content.component.scss']
})
export class TopInfoContentComponent implements OnInit {
  user: User;
  @Input('showInfoContent') showInfoContent: boolean = false;
  @Output() onCloseInfoContent: EventEmitter<any> = new EventEmitter();
  public userImage = "assets/img/users/user.jpg";
  contactForm: FormGroup;
  controls = [
    { name: 'Notifications', checked: true },
    { name: 'Tasks', checked: true },
    { name: 'Events', checked: false },
    { name: 'Downloads', checked: true },
    { name: 'Messages', checked: true },
    { name: 'Updates', checked: false },
    { name: 'Settings', checked: true }
  ]
  constructor(public formBuilder: FormBuilder, private toast: NotificationService, private emailService: EmailService, private authService: AuthService) { }

  ngOnInit() {
    this.user = this.authService.getTheUserConnected();

    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.required, emailValidator]],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }



  public onContactFormSubmit(values: Object): void {
    if (this.contactForm.valid) {


      let body = {
        sender: this.user.email,
        prenom: this.user.firstName,
        nom: this.user.lastName,
        objet: values['subject'],
        message: values['message'],
        receiver: values['email']
      }

      this.emailService.send(body).subscribe(res => {
        console.log(res);
        this.contactForm.reset();
        this.toast.showSuccess("email envoyé avec succès", " Envoi Email");
      }, (err) => { console.log(err) })
    }

  }
  public closeInfoContent(event) {
    this.onCloseInfoContent.emit(event);
  }

}
