import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendServerConf } from 'src/app/pages/core/backend-server-conf';
import { Role } from './role.model';

@Injectable()
export class RoleService {

    readonly url: string = `${BackendServerConf.baseUrlBackend}/api/roles`;
    constructor(public http: HttpClient) { }

    findAll(): Observable<Role[]> {
        return this.http.get<Role[]>(this.url).map(data => data);
    }

    findOneRole(id: string) {
        return this.http.get(this.url + "/" + id);
    }

    createRole(form: Role): Observable<any> {
        return this.http.post(this.url, form);
    }

    delete(id: string) {
        return this.http.delete(`${this.url}/${id}`);
    }

    findOne(id: string) {
        return this.http.get(`${this.url}/${id}`);
    }

    update(form: any) {
        return this.http.put(`${this.url}/${form.id}`, form);
    }


}
