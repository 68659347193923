import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

Injectable({
    providedIn: 'root'
})

export function emailValidator(control: FormControl): { [key: string]: any } {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]{2,}\.[a-z]{2,4}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return { invalidEmail: true };
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password = group.controls[passwordKey];
        let passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({ mismatchedPasswords: true })
        }
    }
}

export function phoneOrEmailValidator(control: FormControl): { [key: string]: any } {
    var emailRegexp = /^(76|77|78)\[1-9][0-9]{7}$/;

    const phoneNumberRegex = /^(76|77|78|75|33|88)\d{7}$/;
    const emailRegex = /^\S+@\S+\.\S+$/;
    // const value = control.value.trim();

    if (phoneNumberRegex.test(control.value) || emailRegex.test(control.value)) {
        return null;
    } else {
        return { 'phoneOrEmail': { value: control.value } };
    }
}

export function phoneValidator(control: FormControl): { [key: string]: any } {

    const phoneNumberRegex = /^(76|77|78|75|33|88)\d{7}$/;
    if (phoneNumberRegex.test(control.value)) {
        return null;
    } else {
        return { 'phone': { value: control.value } };
    }
}

export function formatPhoneNumber(phone: string) {
    let numero = phone.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2-$3-$4 ");
    return numero;
}