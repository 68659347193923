import { Router } from '@angular/router';
import { Injectable, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Menu } from './menu.model';
import { verticalMenuItems, horizontalMenuItems, favoriteMenuItems } from './menu';
import { User } from 'src/app/pages/core/user';
import { AuthService } from 'src/app/pages/core/auth.service';
import { RoleService } from 'src/app/simlait/roles/role.service';
import { Role } from 'src/app/simlait/roles/role.model';

@Injectable()
export class MenuService implements OnInit {
  public roles: Role[] = [];

  user: User;
  constructor(private location: Location, public roleService: RoleService, private auth: AuthService,
    private router: Router) {
    this.user = this.auth.getUser();
  }

  ngOnInit(): void {
    this.roleService.findAll().subscribe(res => this.roles = res['hydra:member']);
    console.log(this.roles);
  }


  public getVerticalMenuItems(): Menu[] {

    let dataMenu: Menu[] = [];
    verticalMenuItems.forEach((e) => {
      let verif = false;
      this.user.roles.forEach(role => {
        if (e.role.find(x => x === role) || e.role.find(x => x === "ALL")) {
          verif = true;
          // dataMenu.push(e);
        }
      })
      if (verif) dataMenu.push(e);
    });

    return dataMenu;
  }

  public getFavoriteMenuItems(): Menu[] {

    let dataMenu: Menu[] = [];
    favoriteMenuItems.forEach((e) => {
      let verif = false;
      this.user.roles.forEach(role => {
        if (e.role.find(x => x === role) || e.role.find(x => x === "ALL")) {
          verif = true;
          // dataMenu.push(e);
        }
      })
      if (verif) dataMenu.push(e);
    });
    return dataMenu;
  }


  public getHorizontalMenuItems(): Menu[] {

    let dataMenu: Menu[] = [];
    horizontalMenuItems.forEach((e) => {
      let verif = false;
      this.user.roles.forEach(role => {
        if (e.role.find(x => x === role) || e.role.find(x => x === "ALL")) {
          verif = true;
          // dataMenu.push(e);
        }
      })
      if (verif) dataMenu.push(e);
    });
    return dataMenu;

  }

  public expandActiveSubMenu(menu: Menu[]) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      }
      else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId) {
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];
    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach(item => {
        if (item.id != menuId) {
          let subMenu = document.getElementById('sub-menu-' + item.id);
          let menuItem = document.getElementById('menu-item-' + item.id);
          if (subMenu) {
            if (subMenu.classList.contains('show')) {
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }
          }
        }
      });
    }
  }

  public closeAllSubMenus() {
    let menu = document.getElementById("vertical-menu");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }


}
