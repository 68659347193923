import { User } from "src/app/pages/core/user";

export class ActivityNotification {

    public id: number;
    public type: string;
    public message: string;
    public user: User;
    public dateOperation: Date;

} 
