import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ConsoleLoggerService {


    disableLogsInProd() {
        if (environment.production) {
            // console.warn(`🚨 Console output is disabled on production!`);
            console.log = function (): void { };
            console.debug = function (): void { };
            console.warn = function (): void { };
            console.info = function (): void { };
            console.error = function (): void { };
            console.trace = function (): void { };
            console.warn = function (): void { };

        }
    }
}
