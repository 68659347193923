import { User } from 'src/app/pages/core/user';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/pages/core/auth.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplicationsComponent implements OnInit {



  user: User;
  constructor(private auth: AuthService) {
    this.user = this.auth.getUser();
    // console.log(this.user);
  }



  ngOnInit() {
  }

}