import { MatDialog } from '@angular/material/dialog';
import { ActivityNotification } from './activityNotifications.model';
import { ActivityNotificationService } from './activityNotifications.service';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthService } from 'src/app/pages/core/auth.service';
import { User } from 'src/app/pages/core/user';
import { MessagesService } from './messages.service';
import { DetailComponent } from './detail/detail.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessagesService]
})
export class MessagesComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public selectedTab: number = 1;
  public messages: Array<Object>;
  public files: Array<Object>;
  public meetings: Array<Object>;

  public notifications: ActivityNotification[] = [];


  public user: User;
  constructor(private messagesService: MessagesService, public dialog: MatDialog, private authService: AuthService,
    private activityNotiS: ActivityNotificationService) {
    this.messages = messagesService.getMessages();
    this.files = messagesService.getFiles();
    this.meetings = messagesService.getMeetings();
  }

  ngOnInit() {
    this.activityNotiS.findAll().subscribe(res => {
      // this.notifications = res.slice(5)
      this.notifications = res.reverse();
      this.notifications = res.slice(0, 4);

    })
    this.user = this.authService.getTheUserConnected();
  }

  isAdmin() {
    return this.user.roles.includes('ROLE_SUPER_ADMIN') || this.user.roles.includes('ROLE_ADMIN');
  }
  openMessagesMenu() {
    this.trigger.openMenu();
    this.selectedTab = 0;
  }

  onMouseLeave() {
    this.trigger.closeMenu();
  }

  stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  openDetail(message) {

    const dialogRef = this.dialog.open(DetailComponent, {
      data: message,

    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(`Dialog result: ${result}`);
    });


  }
}

