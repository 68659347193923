import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { NotificationService } from "src/app/notification.service";
// import { map } from 'rxjs/operators';
import { BackendServerConf } from "./backend-server-conf";
import { User } from './user';


const helper = new JwtHelperService();
const loginHeaders = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    token: "noToken"
  })
};

@Injectable()
export class AuthService {

  readonly apiUrl: string = `${BackendServerConf.baseUrlBackend}`;
  readonly currentUser: string = "currentUser";
  readonly currentUserData: string = "currentUserData";
  readonly currentToken: string = "";

  constructor(private http: HttpClient, private router: Router, private toast: NotificationService) { }
  login(username: string, password: string): Observable<boolean> {
    // if (isDEBUG) console.log(`login with username ${username}`);
    const credentials = { username: username, password: password };
    return this.http
      .post(`${this.apiUrl}/authentication_token`, credentials, {
        observe: "response",
        headers: loginHeaders.headers
      })
      .map(response => {
        const token = response.body["token"];
        const data = response.body["user"];
        const decodedToken = helper.decodeToken(token);
        sessionStorage.setItem(this.currentToken, token);
        sessionStorage.setItem(this.currentUserData, data);
        this.setUser(decodedToken);
        this.TheUserConnected(data);
        return true;
      });
  }

  getUserConnected(): User {
    let user = this.getTheUserConnected();
    console.log(user)
    this.http.get<User>(`${this.apiUrl}/api/users/` + user.id).subscribe(re => {
      user = re;
    });
    return user;
  }


  verifyPassword(username: string, password: string): Observable<boolean> {
    const credentials = { username: username, password: password };
    return this.http
      .post(`${this.apiUrl}/authentication_token`, credentials, {
        observe: "response",
        headers: loginHeaders.headers
      })
      .map(response => {
        const token = response.body["token"];
        const decodedToken = helper.decodeToken(token);
        console.log(decodedToken);

        if (decodedToken.id) {
          return true;
        }
        return false;
      });
  }

  getDetails() {
    this.http.get(`${this.apiUrl}/users/details`, { observe: "response" })
      .subscribe(details => {
        this.setUser(details.body);
      });
  }

  setUser(user: any) {
    sessionStorage.setItem(this.currentUser, JSON.stringify(user));
  }

  TheUserConnected(user: any) {
    sessionStorage.setItem("userConnected", JSON.stringify(user));
  }

  getTheUserConnected(): User {
    return JSON.parse(sessionStorage.getItem("userConnected")) as User;
  }


  getToken(): string {
    return sessionStorage.getItem(this.currentToken);
  }

  isLoggedIn(): boolean {
    const token: string = this.getToken();
    return token && token.length > 0;
  }

  logout(): void {
    if (this.getToken()) {

      let user: User = this.getTheUserConnected();
      user.isActiveNow = false;
      this.updateUser(user).subscribe((res) => {
        sessionStorage.removeItem(this.currentToken);
        sessionStorage.removeItem(this.currentUser);
        sessionStorage.clear();
        this.router.navigate(["/login"]);
      });
    }



  }

  getUser(): User {
    return JSON.parse(sessionStorage.getItem(this.currentUser)) as User;
  }

  getIsSuperAdmin(body): Observable<any> {
    return this.http.post<boolean>(`${this.apiUrl}/api/users/isSuperadmin`, body).map((map) => map);
  }

  setPassSuperAdmin(body): Observable<any> {
    return this.http.post<boolean>(`${this.apiUrl}/api/users/setPassSuperAdmin`, body).map((map) => map);
  }

  getOldPass(id): Observable<any> {
    return this.http.post<boolean>(`${this.apiUrl}/api/users/getoldPass`, id).map((map) => map);
  }

  createPass(pass): Observable<any> {
    return this.http.post<boolean>(`${this.apiUrl}/api/users/createPass`, pass).map((map) => map);
  }


  isAcountActivated() {

    let user: User = this.getTheUserConnected();
    if (!user.enabled) {
      this.logout();
      this.router.navigate(["/login"]);
      this.toast.showError("Votre comptes est inactive", "Compte");
      return;
    }
  }

  register(newData: any) {
    return this.http.post<any>(`${this.apiUrl}/users/register`, newData);
  }

  editUser(newData: any) {
    return this.http.put(`${this.apiUrl}/api/users/${newData.id}`, newData);
  }

  updateUser(body) {
    return this.http.post(`${this.apiUrl}/api/users/profil`, body);
  }

  getUserByEmail(email: string): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/api/users/` + email);
  }
}
