
import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UniteService } from 'src/app/simlait/unite/unite.service';

@Pipe({
  name: 'quantite'
})
export class QuantiteTotalPipe implements PipeTransform {

  constructor(private uniteService: UniteService) { }

  transform(element: any): Observable<boolean> {
    return this.uniteService.findCollecteQuantite(element.id).pipe(
      map((res) => {
        return element.profil.quantite < res['quantite'];
      })
    );
  }
}
