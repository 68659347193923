import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BackendServerConf } from 'src/app/pages/core/backend-server-conf';



const loginHeaders = {
    headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: "noToken"
    })
};


@Injectable({
    providedIn: 'root'
})
export class PrivacyService {

    readonly url: string = `${BackendServerConf.baseUrlBackend}/api/privacy`;

    constructor(public http: HttpClient) { }

    save(body) {
        return this.http.post(`${this.url}`, body, loginHeaders).map(data => data);
    }
    get() {
        return this.http.get(`${this.url}`, loginHeaders).map(data => data);
    }
}
