import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendServerConf } from 'src/app/pages/core/backend-server-conf';

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    readonly url: string = `${BackendServerConf.baseUrlBackend}/api/sendMail`;

    constructor(public http: HttpClient) { }

    send(form) {
        return this.http.post(this.url, form);
    }
}
