import { Menu } from './menu.model';

export const verticalMenuItems = [

  new Menu(1, 'Tableau de Bord', '/', null, 'dashboard', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "TDB"]),
  new Menu(2, 'Utilisateurs', '/users', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(3, 'Utilisateurs mobile', '/user-mobile', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),


  new Menu(4, "Unités", '/unite-mobile', null, 'sd_storage', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(70, "Unités D'identification", '/unite-mobile', null, 'sd_storage', null, false, 4, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(71, "Autre Unités", '/unite-mobile/autres', null, 'sd_storage', null, false, 4, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),


  new Menu(5, 'Cartographie', '/unite-mobile/maps', null, 'maps', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(6, 'Données Brutes', '/donnees-brute', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),

  new Menu(7, 'Producteurs', '/producteurs', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_PRODUCTEUR"]),
  new Menu(8, 'Collecteurs', '/collecte-mobile', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_COLLECTEUR"]),
  new Menu(9, 'Transformateurs', '/transformateurs', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_TRANSFORMATEUR"]),
  // new Menu(11, 'Eleveurs', '/eleveur', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(12, 'Commercialisations', '/commercants', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_COMMERCANT"]),

  // new Menu(32,'historique-requette','/historique-requette',null,'report',null,false,0,true),
  new Menu(32, 'Mise en relation', '/mise-en-relation', null, 'chrome_reader_mode', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(33, 'Zone d\'Intervention', '/collecte-mobile/zones', null, 'chrome_reader_mode', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTEUR", "ROLE_COLLECTEUR", "ROLE_TRANSFORMATEUR", "ROLE_COMMERCANT"]),


  new Menu(34, 'Productions', '/production', null, 'multiline_chart', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),
  new Menu(50, 'Productions', '/production', null, 'multiline_chart', null, false, 34, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),
  new Menu(51, 'Agrégation', '/agregation', null, 'multiline_chart', null, false, 34, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),


  new Menu(35, 'Statistiques', '/statistique', null, 'multiline_chart', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "TDB", "STAT"]),
  new Menu(51, 'Statistique', '/statistique', null, 'multiline_chart', null, false, 35, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "TDB", "STAT"]),
  new Menu(52, 'Agregations', '/agregation', null, 'multiline_chart', null, false, 35, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "TDB", "STAT"]),

  new Menu(36, 'Paramétrage', '/', null, 'settings', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PARAMETRAGE"]),
  new Menu(37, 'Produits', '/produit-mobile', null, 'brightness_low', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(38, 'Profil-Produits', '/profil-produits', null, 'brightness_low', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),

  new Menu(39, 'Emballages', '/emballage-mobile', null, 'assignment', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(40, 'Conditionnements', '/conditionnement-mobile', null, 'call_to_action', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(41, 'Regions', '/region-mobile', null, 'room', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(42, 'Départements', '/departement-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(43, 'Zones', '/zone-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(44, 'Statuts', '/status', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(45, 'Notification-Mobile', '/notifi', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(71, 'config mail/sms', '/config', null, 'settings', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),
  new Menu(73, 'Rôles', '/roles', null, 'settings', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_PARAMETRAGE"]),
  new Menu(72, 'Opérations', '/opérations', null, 'settings', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_PARAMETRAGE"]),


  new Menu(49, 'Paramétre', '/parametrage-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_PARAMETRAGE"]),
  new Menu(50, 'historique', '/historique', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),
  new Menu(51, 'Saisons', '/saisons', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_PARAMETRAGE"]),

]

export const horizontalMenuItems = [

  new Menu(1, 'Tableau de Bord', '/', null, 'dashboard', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "TDB"]),
  new Menu(2, 'Utilisateurs', '/users', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  // new Menu(3, 'Utilisateurs mobile', '/user-mobile', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(4, "Unités D'identification", '/unite-mobile', null, 'sd_storage', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(70, "Autre Unités", '/unite-mobile/autres', null, 'sd_storage', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),

  new Menu(5, 'Cartographie', '/unite-mobile/maps', null, 'maps', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(6, 'Données Brutes', '/donnees-brute', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),

  new Menu(7, 'Producteurs', '/producteurs', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_PRODUCTEUR"]),
  new Menu(8, 'Collecteurs', '/collecte-mobile', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_COLLECTEUR"]),
  new Menu(9, 'Transformateurs', '/transformateurs', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_TRANSFORMATEUR"]),
  // new Menu(11, 'Eleveurs', '/eleveur', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(12, 'Commercialisations', '/commercants', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_COMMERCANT"]),

  // new Menu(32,'historique-requette','/historique-requette',null,'report',null,false,0,true),
  new Menu(32, 'Mise en relation', '/mise-en-relation', null, 'chrome_reader_mode', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTEUR", "ROLE_COLLECTEUR", "ROLE_TRANSFORMATEUR", "ROLE_COMMERCANT"]),
  new Menu(33, 'Zone d\'Intervention', '/collecte-mobile/zones', null, 'chrome_reader_mode', null, false, 0, true, ["ROLE_PRODUCTEUR", "ROLE_COLLECTEUR", "ROLE_TRANSFORMATEUR", "ROLE_COMMERCANT"]),

  new Menu(34, 'Productions', '/production', null, 'multiline_chart', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),
  new Menu(50, 'Productions', '/production', null, 'multiline_chart', null, false, 34, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),
  new Menu(51, 'Agrégation', '/agregation', null, 'multiline_chart', null, false, 34, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT",]),


  new Menu(35, 'Statistiques', '/statistique', null, 'multiline_chart', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),
  new Menu(51, 'Statistique', '/statistique', null, 'multiline_chart', null, false, 35, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),
  new Menu(52, 'Agregations', '/agregation', null, 'multiline_chart', null, false, 35, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),

  // new Menu(36, 'Paramétrage', '/', null, 'settings', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN","ROLE_PARAMETRAGE"]),
  new Menu(37, 'Produits', '/produit-mobile', null, 'brightness_low', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(38, 'Profil-Produits', '/profil-produits', null, 'brightness_low', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),

  new Menu(39, 'Emballages', '/emballage-mobile', null, 'assignment', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(40, 'Conditionnements', '/conditionnement-mobile', null, 'call_to_action', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(41, 'Regions', '/region-mobile', null, 'room', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(42, 'Départements', '/departement-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(43, 'Zones', '/zone-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(44, 'Statuts', '/status', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(45, 'Notification-Mobile', '/notifi', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(71, 'config mail/sms', '/config', null, 'settings', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),

  new Menu(72, 'Opérations', '/opérations', null, 'settings', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_PARAMETRAGE"]),
  new Menu(73, 'Rôles', '/roles', null, 'settings', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_PARAMETRAGE"]),

  new Menu(49, 'Paramétre', '/parametrage-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),
  new Menu(50, 'historique', '/historique', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),
  new Menu(51, 'Saisons', '/saisons', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_PARAMETRAGE"]),


]

export const favoriteMenuItems = [


  new Menu(1, 'Tableau de Bord', '/', null, 'dashboard', null, false, 0, true, ["TDB"]),
  new Menu(2, 'Utilisateurs', '/users', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(3, 'Utilisateurs mobile', '/user-mobile', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(4, "Unités D'identification", '/unite-mobile', null, 'sd_storage', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  // new Menu(70, "Autre Unités", '/unite-mobile/autre', null, 'sd_storage', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),

  new Menu(5, 'Cartographie', '/unite-mobile/maps', null, 'maps', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(6, 'Données Brutes', '/donnees-brute', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),

  new Menu(7, 'Producteurs', '/producteurs', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_PRODUCTEUR"]),
  new Menu(8, 'Collecteurs', '/collecte-mobile', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_COLLECTEUR"]),
  new Menu(9, 'Transformateurs', '/transformateurs', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_TRANSFORMATEUR"]),
  // new Menu(11, 'Eleveurs', '/eleveur', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(12, 'Commercialisations', '/commercants', null, 'report', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_COMMERCANT"]),

  // new Menu(32,'historique-requette','/historique-requette',null,'report',null,false,0,true),
  new Menu(32, 'Mise en relation', '/mise-en-relation', null, 'chrome_reader_mode', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),

  new Menu(34, 'Productions', '/production', null, 'multiline_chart', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(50, 'Productions', '/production', null, 'multiline_chart', null, false, 34, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(51, 'Agrégation', '/agregation', null, 'multiline_chart', null, false, 34, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),


  new Menu(35, 'Statistiques', '/statistique', null, 'multiline_chart', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "TDB", "STAT"]),
  new Menu(51, 'Statistique', '/statistique', null, 'multiline_chart', null, false, 35, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "TDB", "STAT"]),
  new Menu(52, 'Agregations', '/agregation', null, 'multiline_chart', null, false, 35, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "TDB", "STAT"]),

  new Menu(36, 'Paramétrage', '/', null, 'settings', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(37, 'Produits', '/produit-mobile', null, 'brightness_low', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(38, 'Profil-Produits', '/profil-produits', null, 'brightness_low', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),

  new Menu(39, 'Emballages', '/emballage-mobile', null, 'assignment', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(40, 'Conditionnements', '/conditionnement-mobile', null, 'call_to_action', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(41, 'Regions', '/region-mobile', null, 'room', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(42, 'Départements', '/departement-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(43, 'Zones', '/zone-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(44, 'Statuts', '/status', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(45, 'Notification-Mobile', '/notifi', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(71, 'config mail/sms', '/config', null, 'settings', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),
  new Menu(73, 'Rôles', '/roles', null, 'settings', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),

  new Menu(72, 'Opérations', '/opérations', null, 'settings', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),
  new Menu(49, 'Paramétre', '/parametrage-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),

  new Menu(50, 'historique', '/historique', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN"]),

]